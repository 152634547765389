nav {
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.algomint-logo {
  width: 200px;
  height: 100px;
  cursor: pointer;
}

.nav-item {
  font-weight: 400;
  text-transform: uppercase;
  background-size: 0 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

.nav-item:hover {
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(aqua, aqua);
  background-size: 100% 1px;
  background-position: 0 80%;
}

.nav-item-selected {
  border-bottom-width: 1px;
  border-bottom-color: aqua;
}
