*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 810px) {
  .container {
    max-width: 810px;
  }
}

@media (min-width: 1159px) {
  .container {
    max-width: 1159px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1359px) {
  .container {
    max-width: 1359px;
  }
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-screen {
  height: 100vh;
}

.h-9 {
  height: 2.25rem;
}

.h-16 {
  height: 4rem;
}

.w-96 {
  width: 24rem;
}

.w-full {
  width: 100%;
}

.w-80 {
  width: 20rem;
}

.w-screen {
  width: 100vw;
}

.w-44 {
  width: 11rem;
}

.w-16 {
  width: 4rem;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-6 {
  gap: 1.5rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-scroll {
  overflow: scroll;
}

.break-all {
  word-break: break-all;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-\[\#1BFED7\] {
  --tw-border-opacity: 1;
  border-color: rgb(27 254 215 / var(--tw-border-opacity));
}

.bg-\[\#232328\] {
  --tw-bg-opacity: 1;
  background-color: rgb(35 35 40 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-\[\#1BFED7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 254 215 / var(--tw-bg-opacity));
}

.bg-\[\#1e1e1e\] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 30 30 / var(--tw-bg-opacity));
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-light {
  font-weight: 300;
}

.font-semibold {
  font-weight: 600;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.duration-200 {
  transition-duration: .2s;
}

.hover\:bg-\[\#1BFED7\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 254 215 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.focus\:outline-double:focus {
  outline-style: double;
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-\[\#232328\] {
    --tw-bg-opacity: 1;
    background-color: rgb(35 35 40 / var(--tw-bg-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:text-\[\#1BFED7\] {
    --tw-text-opacity: 1;
    color: rgb(27 254 215 / var(--tw-text-opacity));
  }

  .dark\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}

@media (max-width: 575px) {
  .xs\:max-h-28 {
    max-height: 7rem;
  }

  .xs\:w-32 {
    width: 8rem;
  }

  .xs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xs\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xs\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (min-width: 576px) and (max-width: 809px) {
  .sm\:max-h-28 {
    max-height: 7rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 810px) and (max-width: 1199px) {
  .md\:max-h-28 {
    max-height: 7rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1200px) {
  .lg\:max-h-28 {
    max-height: 7rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1159px) {
  .xl\:max-h-28 {
    max-height: 7rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1359px) {
  .\32 xl\:max-h-28 {
    max-height: 7rem;
  }

  .\32 xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover, input[type="number"]:focus {
  -moz-appearance: number-input;
}

.Transaction-details-wrapper {
  border-radius: 20px;
}

.accordion-wrapper {
  border-radius: 20px;
}

.accordion-content {
  max-height: 0;
  line-height: 1.6;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.accordion-content.show {
  height: auto;
  max-height: 9999px;
  padding: .8em;
  transition: max-height .5s ease-in-out;
}

nav {
  z-index: 1000;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.algomint-logo {
  width: 200px;
  height: 100px;
  cursor: pointer;
}

.nav-item {
  text-transform: uppercase;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  font-weight: 400;
  transition: background-size .2s ease-in-out;
}

.nav-item:hover {
  background-image: linear-gradient(#0000, #0000), linear-gradient(#0ff, #0ff);
  background-position: 0 80%;
  background-size: 100% 1px;
}

.nav-item-selected {
  border-bottom-width: 1px;
  border-bottom-color: #0ff;
}

/*# sourceMappingURL=index.f4cd403d.css.map */
